<template>
    <panel-alert title="选择" :isShowInfo="false" isShowBottom width="450px" @onCloseClick="onCloseClick"
        @onConfirmClick="onConfirmClick">
        <template v-slot:content>
            <div class="edit-alert">
                <div class="key-value-item">
                    <div class="question-title">{{ '图片名: ' }}</div>
                    <el-input placeholder="请输入" v-model="picName">
                    </el-input>
                </div>
                <BaseLineInfoCardImg class="image-box" :index="0" :image-list="localFileUrl ? [{name:'',imageUrl:localFileUrl}] : null" :is-edit="true"
                    @onClickAdd="addPic" @onClickDelete="deletePic" hidden-title></BaseLineInfoCardImg>
            </div>
        </template>
    </panel-alert>
</template>
  
<script>
import PanelAlert from "@c/common/alert/panel-alert/panel-alert.vue";
import BaseLineInfoCardImg from './baseline-info-card-img.vue'
import {
    Input
} from "element-ui";

export default {
    components: {
        PanelAlert,
        elInput: Input,
        BaseLineInfoCardImg
    },

    data: function () {
        return {
            picName: '',
            localFileUrl: null,
            file: null,
        };
    },

    computed: {

    },


    created() {

    },

    methods: {
        onCloseClick: function () {
            this.$emit("onCloseClick");
        },

        async onConfirmClick() {
            if (this.picName == '') {
                this.$toast.showRed('请输入图片名')
                return
            }
            if (!this.localFileUrl || this.localFileUrl == '') {
                this.$toast.showRed('请选择图片')
                return
            }
            try {
                const photoURL = await this.$api.uplodFileRequest(this.file)
                this.$emit("onSucceed", {
                    name: this.picName,
                    imageUrl: photoURL,
                    type: 1
                });
            } catch (error) {
                this.$toast.showRed(error)
            }
        },
        addPic(file) {
            if (file) {
                const fileURL = URL.createObjectURL(file);
                this.localFileUrl = fileURL
                this.file = file
            } else {
                console.log('未选择文件');
            }
        },
        deletePic() {
            this.localFileUrl = null
            this.file = null
        }
    }
}
</script>
  
<style lang="scss" scoped>
/deep/.panel-alert-content {
    padding-right: 24px;
    padding-bottom: 10px;
}

/deep/.panel-alert-bottom {
    border: none;
}

.edit-alert {
    &-content {
        // display: flex;
        // justify-content: space-between;
        // flex-wrap: wrap;
        box-sizing: border-box;
        padding-right: 20px;
    }

    &-line {
        background-color: rgba($color: #000000, $alpha: 0.09);
        width: 100%;
        height: 1px;
        margin-top: 9px;
    }
}

.key-value-item {
    margin-top: 9px;
    margin-bottom: 15px;

    .question-title {
        margin-bottom: 10px;
        width: 100%;
        height: 30px;
        font-size: 14px;
        line-height: 30px;
    }

    /deep/.el-input__inner {
        font-size: 14px;
        height: 32px;
        width: 400px;
    }

    /deep/.el-input__icon {
        line-height: 32px;
    }
}
</style>
  
  